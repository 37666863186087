/**
 * Represents a microQuery selector object.
 * @constructor
 * @version 0.2.9
 * @param {string|microQuery|Node|Element} selector
 * @example
 * // returns microQuery
 * µ("body");
 */
const microQuery = function (selector)
{
    this.selector = selector || null;
    this.elements = [];
    this.length = 0;
    
    this.microQueryVersion = "0.2.9";
    
    return this;
};

microQuery.prototype.init = function()
{
    if (this.elements.length === 0 && this.selector !== null)
    {
        switch (typeof this.selector)
        {
            case "object":
                if (typeof this.selector.nodeName !== "undefined")
                {
                    if (this.selector.nodeName === "#document")
                    {
                        this.elements.push(this.selector);
                        this.selector = "document";
                    }
                    else
                    {
                        this.elements.push(this.selector);
                        this.selector = null;
                    }
                }
                else if (typeof this.selector.window !== "undefined")
                {
                    this.elements.push(this.selector);
                    this.selector = "window";
                }
                else if (typeof this.selector.microQueryVersion !== "undefined")
                {
                    return this.selector;
                }
                
                break;
                
            case "string":
                if (this.selector.trim().substr(0,1) === "<")
                {
                    //create element/s
                    const newNode = document.createElement("template");
                    newNode.innerHTML = this.selector.trim();
                    for (let i = 0; i < newNode.content.childNodes.length; i++) this.elements.push(newNode.content.childNodes[i]);
                    this.selector = null;
                }
                else if (this.selector === "document")
                    this.elements.push(document);
                else if (this.selector === "window")
                    this.elements.push(window);
                else
                    this.elements = Array.from(document.querySelectorAll(this.selector));
                break;
        }
    }
    
    // add custom data structure
    this.elements.forEach(el =>
    {
        if (typeof el.microQueryData === "undefined") el.microQueryData = {events: {}, customdataset: {}};
    });
    
    this.length = this.elements.length;
    
    return this;
};

microQuery.prototype.eventHandler = {
    
    bind: function(event, callback, targetElement)
    {
        this.unbind(event,targetElement);
        targetElement.addEventListener(event.split(".")[0], callback, true);
    },
    
    bindAllFromStorage: function(targetElement)
    {
        const eventArrayList = Object.entries(this.getAll(targetElement));
        if (eventArrayList.length > 0)
            for (let [event, eventData] of eventArrayList)
                new microQuery(targetElement).init().on(event, eventData.target, eventData.userCallback);
    },
    
    get: function(event, targetElement)
    {
        if (typeof targetElement.microQueryData.events[event] !== "undefined")
            return targetElement.microQueryData.events[event];
        else
            return null;
    },
    
    getAll: function(targetElement)
    {
        return targetElement.microQueryData.events;
    },
    
    unbind: function(event, targetElement)
    {
        const foundEvent = this.get(event, targetElement);
        if (foundEvent !== null)
            targetElement.removeEventListener(event.split(".")[0], foundEvent.callback, true);
    }
};

microQuery.prototype.stringOperations = {
    
    toCamelCase:  s => s.split("-").map((w, i) => (i > 0 ? w[0].toUpperCase() : w[0]) + w.slice(1)).join("")
    
};


//microQuery.prototype.on = function(event, target, callback)
//{
//    let cb = function () {};
//    if (typeof target === "function")
//        cb = target;
//    else
//        cb = callback;
//
//    this.elements.forEach(element => this.eventHandler.bindEvent(event, function (e)
//    {
//        let sameElement = false;
//        const targetElement = new microQuery(target).init();
//
//        // execute callback
//        // ... when event target is the target element
//        targetElement.elements.forEach(targetElement => { if (targetElement === e.target) return true; });
//
//        // ... or when not then check if a parent is the target element
//        const eventTarget = new microQuery();
//        eventTarget.elements.push(e.target);
//        eventTarget.init();
//
//        if (eventTarget.parents(target).length > 0)
//            return true;
//
//        console.log("false");
//        e.stopPropagation();
//        return false;
//
//    }, element));
//
//    this.elements.forEach(element => this.eventHandler.bindEvent(event, cb, element));
//
//    return this;
//};


microQuery.prototype.on = function(event, target, userCallback)
{
    const _this = this;
    
    let cb = function () {};
    if (typeof target === "function")
        cb = target;
    else
    {
        target = target.trim();
        cb = function (e)
        {
            let sameElement = false;
            let targetElements = _this.find(target);
        
            // execute callback
            // ... when event target is the target element
            targetElements.elements.forEach(targetElement => { if (targetElement === e.target) { sameElement = true; userCallback.call(new microQuery(e.target).init(), e); } });
        
            // ... or when not then check if a parent is the target element
            if (!sameElement)
            {
                const eventTarget = new microQuery(e.target).init();
                const parents = eventTarget.parents((target[0] === ">") ?target.slice(1) : target);
                parents.elements.some(parent => targetElements.elements.some(targetElement => { if (targetElement === parent) { userCallback.call(new microQuery(targetElement).init(), e); return true; } }));
            }
        }
    }
    
    this.elements.forEach(element =>
    {
        let callback = function (e) { cb.call(new microQuery(element).init(), e) };
        this.eventHandler.bind(event, callback, element);
    
        // save event to element
        element.microQueryData.events[event] = {target: target, userCallback: userCallback, callback: callback};
    });
    
    return this;
};


microQuery.prototype.off = function(event)
{
    this.each(el =>
    {
        if (event !== undefined)
        {
            el.eventHandler.unbind(event, el.elements[0]);
            delete el.elements[0].microQueryData.events[event];
        }
        else
        {
            for (let key of Object.keys(el.eventHandler.getAll(el.elements[0])))
                el.eventHandler.unbind(key, el.elements[0]);
    
            el.elements[0].microQueryData.events = {};
        }
    });
    
    return this;
};


microQuery.prototype.trigger = function(event, data)
{
    let e;
    let ie = false;
    if(document.createEvent)
    {
        e = document.createEvent("HTMLEvents");
        e.initEvent(event, true, true);
    }
    else
    {
        ie = true;
        e = document.createEventObject();
        e.eventType = event;
    }
    
    e.eventName = event;
    
    // add data to event when set
    if (data !== undefined) e.mentenEventData = data;
    
    this.elements.forEach(element =>
    {
        if (!ie)
            element.dispatchEvent(e);
        else
            element.fireEvent("on" + e.eventType, e);
    });
    
    return this;
};


microQuery.prototype.click = function()
{
    return this.trigger("click");
};


microQuery.prototype.clone = function()
{
    function objArrClone(objArr)
    {
        if (Object.prototype.toString.call(objArr) === '[object Array]')
        {
            let clone = [];
            for (let i = 0; i < objArr.length; i++)
                clone[i] = objArrClone(objArr[i]);
        
            return clone;
        }
        else if (typeof(objArr)=="object")
        {
            let clone = {};
            for (let prop in objArr)
                if (objArr.hasOwnProperty(prop))
                    clone[prop] = objArrClone(objArr[prop]);
        
            return clone;
        }
        else
            return objArr;
    }
    
    function customClone(n)
    {
        let clone = n.cloneNode();
        Object.keys(n).forEach(property =>
        {
            clone[property] = objArrClone(n[property]);
        });
        
        // recursivly
        for (let i = 0; i < n.childNodes.length; i++)
            clone.appendChild(customClone(n.childNodes[i]));
        
        return clone;
    }
    
    const clone = new microQuery();
    this.each(el =>
    {
        let clonedEl = new microQuery(customClone(el.elements[0])).init();
        
        // re-bind events from storage
        // first only for the root element
        clonedEl.eventHandler.bindAllFromStorage(clonedEl.elements[0]);
        // and for all children
        clonedEl.find("*").each(child => child.eventHandler.bindAllFromStorage(child.elements[0]));
        
        clone.elements.push(clonedEl.elements[0]);
    });
    return clone.init();
};


microQuery.prototype.val = function(newVal)
{
    if (newVal === undefined && this.length > 0)
        return this.elements[0].value;
        
    this.elements.forEach(element => element.value = newVal);
    
    return this;
};


microQuery.prototype.focus = function(options)
{
    const _this = this;
    if (options === undefined) options = {preventScroll:true};
    if (_this.length > 0) setTimeout(function () { _this.elements[0].focus(options); }, 1);
    return _this;
};


microQuery.prototype.select = function()
{
    try
    {
        if (this.length > 0) this.elements[0].select();
    }
    catch (e) {}
    return this;
};


microQuery.prototype.next = function()
{
    const newEl = new microQuery();
    this.each(el => (el.elements[0].nextElementSibling !== null ? newEl.elements.push(el.elements[0].nextElementSibling) : true));
    return newEl.init();
};


microQuery.prototype.prev = function()
{
    const newEl = new microQuery();
    this.each(el => (el.elements[0].previousElementSibling !== null ? newEl.elements.push(el.elements[0].previousElementSibling) : true));
    return newEl.init();
};



microQuery.prototype.append = function(html)
{
    // when appending only content
    if (typeof html !== "object" && html.toString().trim().substr(0,1) !== "<")
    {
        this.elements.forEach(element => element.innerHTML = element.innerHTML + html);
        return this;
    }
    
    // else when appending a dom string or microQuery
    if (typeof html === "string") html = new microQuery(html).init();
    this.elements.forEach((element, index) => (index < this.length - 1 ? html.clone() : html).elements.forEach(el => element.append(el)));
    
    // execute script tags
    html.elements.forEach(el => { if (el.nodeName === "SCRIPT") eval(el.innerHTML); });
    html.find("script").elements.forEach(el => eval(el.innerHTML));
    
    return this;
};


microQuery.prototype.prepend = function(html)
{
    // when prepending only a string
    if (typeof html !== "object" && html.toString().trim().substr(0,1) !== "<")
    {
        this.elements.forEach(element => element.innerHTML = html + element.innerHTML);
        return this;
    }
    
    // else when prepending a dom string or microQuery
    if (typeof html === "string") html = new microQuery(html).init();
    this.elements.forEach((element, index) => (index < this.length - 1 ? html.clone().elements : Array.from(html.elements)).reverse().forEach(el => element.prepend(el)));
    
    // execute script tags
    html.elements.forEach(el => { if (el.nodeName === "SCRIPT") eval(el.innerHTML); });
    html.find("script").elements.forEach(el => eval(el.innerHTML));
    
    return this;
};



microQuery.prototype.insertAfter = function(html)
{
    // when inserting only a string
    if (typeof html !== "object" && html.toString().trim().substr(0,1) !== "<")
    {
        this.elements.forEach(element => element.parentNode.insertBefore(document.createTextNode(html), element.nextSibling));
        return this;
    }
    
    // else when inserting a dom string or microQuery
    if (typeof html === "string") html = new microQuery(html).init();
    this.elements.forEach((element, index) => (index < this.length - 1 ? html.clone().elements : Array.from(html.elements)).reverse().forEach(el => element.parentNode.insertBefore(el, element.nextSibling)));
    
    // execute script tags
    html.elements.forEach(el => { if (el.nodeName === "SCRIPT") eval(el.innerHTML); });
    html.find("script").elements.forEach(el => eval(el.innerHTML));
    
    return this;
};



microQuery.prototype.insertBefore = function(html)
{
    // when inserting only a string
    if (typeof html !== "object" && html.toString().trim().substr(0,1) !== "<")
    {
        this.elements.forEach(element => element.parentNode.insertBefore(document.createTextNode(html), element));
        return this;
    }
    
    // else when inserting a dom string or microQuery
    if (typeof html === "string") html = new microQuery(html).init();
    this.elements.forEach((element, index) => (index < this.length - 1 ? html.clone().elements : Array.from(html.elements)).reverse().forEach(el => element.parentNode.insertBefore(el, element)));
    
    // execute script tags
    html.elements.forEach(el => { if (el.nodeName === "SCRIPT") eval(el.innerHTML); });
    html.find("script").elements.forEach(el => eval(el.innerHTML));
    
    return this;
};



microQuery.prototype.wrap = function(html)
{
    const wrapEl = new microQuery(html).init();
    
    if (wrapEl.length > 0)
    {
        this.each(el =>
        {
            const cloneWrapEl = new microQuery(wrapEl.elements[0]).init().clone();
            el.insertBefore(cloneWrapEl);
            if (cloneWrapEl.children().length === 0)
                cloneWrapEl.append(el);
            else
            {
                cloneWrapEl.find("*").some(child =>
                {
                    if (child.children().length === 0)
                    {
                        child.append(el);
                        return true;
                    }
                });
            }
        });
    }
    else
        console.error("wrapper element length === 0", wrapEl);
    
    return this;
};



microQuery.prototype.wrapInner = function(html)
{
    const wrapEl = new microQuery(html).init();
    
    if (wrapEl.length > 0)
    {
        this.each(el =>
        {
            const cloneWrapEl = new microQuery(wrapEl.elements[0]).init().clone();
            const wrappedContent = new microQuery();
            el.elements[0].childNodes.forEach(child => wrappedContent.elements.push(child));
            
            if (cloneWrapEl.children().length === 0)
                cloneWrapEl.append(wrappedContent);
            else
            {
                cloneWrapEl.find("*").some(child =>
                {
                    if (child.children().length === 0)
                    {
                        child.append(wrappedContent);
                        return true;
                    }
                });
            }
            
            el.append(cloneWrapEl);
        });
    }
    else
        console.error("wrapper element length === 0", wrapEl);
    
    return this;
};



microQuery.prototype.wrapAll = function(html)
{
    const wrapEl = new microQuery(new microQuery(html).init().elements[0]).init();
    
    if (this.length > 0)
    {
        if (wrapEl.length > 0)
        {
            new microQuery(this.elements[0]).init().insertBefore(wrapEl);
            
            
            if (wrapEl.children().length === 0)
                wrapEl.append(this);
            else
            {
                wrapEl.find("*").some(child => {
                    if (child.children().length === 0)
                    {
                        child.append(this);
                        return true;
                    }
                });
            }
        }
        else
            console.error("wrapper element length === 0", wrapEl);
    }
    
    return this;
};



microQuery.prototype.unwrap = function()
{
    this.each(el =>
    {
        const wrappedContent = new microQuery();
        const wrapper = el.parents().first();
        wrapper.elements[0].childNodes.forEach(child => wrappedContent.elements.push(child));
        wrapper.insertBefore(wrappedContent.init()).remove();
    });
    
    return this;
};



microQuery.prototype.unwrapInner = function()
{
    this.each(el =>
    {
        const wrappedContent = new microQuery();
        el.elements[0].childNodes.forEach(child => wrappedContent.elements.push(child));
        el.insertBefore(wrappedContent.init()).remove();
    });
    
    return this;
};



microQuery.prototype.children = function()
{
    return this.find("> *");
};



microQuery.prototype.sort = function()
{
    const debug = false;
    
    let target = null;
    let sortBy = null;
    
    // debug log
    if (debug)
    {
        console.log(this);
        console.log("arguments:");
        console.log(arguments);
        console.log("");
    }
    
    try
    {
        // if no args are given
        if (typeof arguments[0] === "undefined")
        {
            sortBy = [["text"]];
        }
        // check if simple sort is uses
        else if (typeof arguments[0] === "string")
        {
            if (["asc", "desc"].includes(arguments[0].toString().toLowerCase()))
                sortBy = [["text", arguments[0]]];
            else
            {
                sortBy = [[arguments[0]]];
    
                if (typeof arguments[1] === "string") sortBy[0][1] = arguments[1];
                if (typeof arguments[2] === "string") sortBy[0][2] = arguments[2];
            }
        }
        // check if sortBy setting exist and is valid
        else if (Array.isArray(arguments[0]) && arguments[0].length > 0)
        {
            if (Array.isArray(arguments[0][0]))
                sortBy = arguments[0];
            else
            {
                sortBy = [];
                for (let i = 0; i < arguments.length; i++)
                    sortBy[sortBy.length] = arguments[i];
            }
            
        }
        // check if sortBy setting exist and is valid
        else if (typeof arguments[0].sortBy !== "undefined" && Array.isArray(arguments[0].sortBy) && arguments[0].sortBy.length !== 0)
            sortBy = arguments[0].sortBy;
        else
            throw "(check simple sort) invalid sortBy";
    
        // debug log
        if (debug)
        {
            console.log("sortBy:");
            console.log(typeof sortBy);
            console.log(sortBy);
            console.log("");
        }
        
        // check sortBy
        sortBy.forEach(sb =>
        {
            if (Array.isArray(sb) && sb.length >= 1 && sb.length <= 3)
            {
                switch (sb.length)
                {
                    case 3: // only used when type is attr or data and can only be asc or desc
                        if (!["asc", "desc"].includes(sb[2].toLowerCase())) throw "(check sortBy 3 params) invalid sortBy direction '" + sb[2] + "'";
                    case 2: // when type = text then second param can only be asc/desc, when type is attr or data then second param is the corresponding name
                        if ("text" === sb[0].toLowerCase() && !["asc", "desc"].includes(sb[1].toLowerCase())) throw "(check sortBy 2 params) invalid sortBy direction '" + sb[1] + "'";
                    case 1: // first param is type and can only be text, attr or data
                        if (!["text", "attr", "data"].includes(sb[0].toLowerCase())) throw "(check sortBy) invalid sortBy type '" + sb[0] + "'";
                        if (["attr", "data"].includes(sb[0].toLowerCase()) && typeof sb[1] !== "string") throw "(check sortBy) invalid sortBy second param for type '" + sb[0] + "'";
                        break;
                }
            }
            else
                throw "(check sortBy) invalid sortBy";
        });
        
        
        // check target
        // when no target is given use parent
        if (typeof arguments[0] === "undefined" || typeof arguments[0].target === "undefined")
        {
            const parent = this.parents().first();
            if (parent.length === 1)
                target = parent;
            else
                throw "(check target - parent) invalid target";
        }
        // when target is string use it as selector
        else if (typeof arguments[0].target === "string")
            target = new microQuery(arguments[0].target).init();
        // when target is microQuery use it
        else if (typeof arguments[0].target.microQueryVersion !== "undefined")
            target = arguments[0].target;
        else
            throw "(check target) invalid target";
    
    
        // debug log
        if (debug)
        {
            console.log("target:");
            console.log(target);
            console.log("");
        }
        
        
        // sort function for recursiv use
        function runSortBy(a, b, index)
        {
            let sortByIndex = 0;
            if (typeof index !== "undefined")
                sortByIndex = index;
            
            let type = sortBy[sortByIndex][0].toLowerCase();
            let secondParam;
            let thirdParam = null;
            
            // check if second param is given
            if (typeof sortBy[sortByIndex][1] !== "undefined")
            {
                secondParam = sortBy[sortByIndex][1];
                
                // check if third param is given
                thirdParam = typeof sortBy[sortByIndex][2] !== "undefined" ? sortBy[sortByIndex][2] : "asc";
            }
            else
                secondParam = "asc";
            
            
            // get defined values
            let valueA = null;
            let valueB = null;
            
            const compareA = new microQuery(a).init();
            const compareB = new microQuery(b).init();
            
            if (type === "text")
            {
                valueA = compareA.text();
                valueB = compareB.text();
            }
            else if (type === "attr")
            {
                valueA = compareA.attr(secondParam);
                valueB = compareB.attr(secondParam);
            }
            else if (type === "data")
            {
                valueA = compareA.data(secondParam);
                valueB = compareB.data(secondParam);
            }
            
            
            // check values
            if (valueA == null || valueB == null) throw "(check value - type '" + type + "' and value '" + secondParam + "') null value";
            
            
            // debug log
            if (debug)
                console.log("sortByIndex " + sortByIndex + " - a: " + valueA + "(string = " + isNaN(valueA) + ")" + " - b: " + valueB + "(string = " + isNaN(valueB) + ")");
            
            // if value of a < value of b
            if (
                (valueA !== "" && valueB !== "" && !isNaN(valueA) && !isNaN(valueB) && parseFloat(valueA) < parseFloat(valueB))
                ||
                (valueA !== "" && valueB !== "" && isNaN(valueA) && isNaN(valueB) && !isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB)) && Date.parse(valueA) < Date.parse(valueB))
                ||
                (isNaN(valueA) && isNaN(valueB) && valueA.toString().toUpperCase().localeCompare(valueB.toString().toUpperCase()) < 0)
            )
                return (
                    (type === "text" && secondParam.toString().toLowerCase() === "asc")
                    ||
                    (["attr", "data"].includes(type) && thirdParam.toString().toLowerCase() === "asc")
                ) ? -1 : 1;
        
            // if value of a > value of b
            if (
                (valueA !== "" && valueB !== "" && !isNaN(valueA) && !isNaN(valueB) && parseFloat(valueA) > parseFloat(valueB))
                ||
                (valueA !== "" && valueB !== "" && isNaN(valueA) && isNaN(valueB) && !isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB)) && Date.parse(valueA) > Date.parse(valueB))
                ||
                (isNaN(valueA) && isNaN(valueB) && valueA.toString().toUpperCase().localeCompare(valueB.toString().toUpperCase()) > 0)
            )
                return (
                    (type === "text" && secondParam.toString().toLowerCase() === "asc")
                    ||
                    (["attr", "data"].includes(type) && thirdParam.toString().toLowerCase() === "asc")
                ) ? 1 : -1;
        
            // increment index for possible next run
            sortByIndex++;
            
            // if values are the same
            if (typeof sortBy[sortByIndex] === "undefined")
                return 0;
            else
                return runSortBy(a,b, sortByIndex);
        }
        
        // start the sort
        this.elements.sort((a,b) => runSortBy(a,b));
        
        // write to dom
        target.html("");
        this.elements.forEach(el => target.append(new microQuery(el).init()));
        
    }
    catch (e)
    {
        console.error(e);
        console.warn("no sorting done!");
        return this;
    }
    
    // debug log
    if (debug)
        console.log(this);
    
    return this;
};


microQuery.prototype.replace = function(html)
{
    this.insertAfter(html);
    this.remove();
    
    return this;
};


microQuery.prototype.remove = function()
{
    this.elements.forEach(element => element.remove());
    
    return this;
};


microQuery.prototype.html = function(html)
{
    if (html === undefined && this.length > 0) return this.elements[0].innerHTML;
    
    this.elements.forEach(element =>
    {
        element.innerHTML = "";
        new microQuery(element).init().append(html);
    });
    
    return this;
};


microQuery.prototype.text = function(text)
{
    if (text === undefined && this.length > 0) return this.elements[0].textContent;
    
    this.elements.forEach(element =>
    {
        element.innerText = text;
    });
    
    return this;
};


microQuery.prototype.loadScript = function(src, callback, type)
{
    if (typeof src === "string")
    {
        const script = document.createElement('script');
    
        if (typeof callback === "function")
            script.onload = callback;
        
        script.src = src;
        
        if (typeof type === "string")
            script.type = type;
        
        this.elements[0].appendChild(script);
    }
    return this;
};


microQuery.prototype.loadStyle = function(href, callback)
{
    if (typeof href === "string")
    {
        const _this = this;
        const req = new XMLHttpRequest();
    
        req.onreadystatechange = function()
        {
            if( req.readyState === 4 )
            {
                const style = document.createElement('style');
                
                style.setAttribute("type", "text/css");
                
                style.textContent = style.text = req.responseText;
    
                _this.elements[0].appendChild(style);
    
                if (typeof callback === "function")
                    callback();
            }
        };
    
        req.open('GET', href, true);
        req.send(null);
    }
    return this;
};


microQuery.prototype.addClass = function(classString)
{
    this.elements.forEach(el => el.classList.add(...classString.split(" ")));
    return this;
};


microQuery.prototype.removeClass = function(classString)
{
    this.elements.forEach(el => el.classList.remove(...classString.split(" ")));
    return this;
};


microQuery.prototype.toggleClass = function(classString)
{
    this.elements.forEach(el => classString.split(" ").forEach(className => el.classList.toggle(className)));
    return this;
};


microQuery.prototype.hasClass = function(classString)
{
    return this.elements.some(el => el.classList.contains(classString));
};


microQuery.prototype.data = function(key, value)
{
    if (this.length > 0)
    {
        key = key.split("-").map((w, i) => (i > 0 ? w[0].toUpperCase() : w[0]) + w.slice(1)).join("");
    
        if (typeof value === "undefined")
            if (typeof this.elements[0].microQueryData.customdataset[key] !== "undefined")
                return this.elements[0].microQueryData.customdataset[key];
            else if (typeof this.elements[0].dataset[key] !== "undefined")
                return this.elements[0].dataset[key];
            else
                return null;
        else
        {
            if (value !== null)
                this.each(el =>
                {
                    //write value to dataset
                    el.elements[0].dataset[key] = value;
                    
                    // write value to microQuery custom Data
                    el.elements[0].microQueryData.customdataset[key] = value;
                });
            else
                this.each(el =>
                {
                    // remove data from dataset
                    el.elements[0].removeAttribute("data-" + key.split(/(?=[A-Z])/).join("-").toLowerCase());
                    
                    // remove data from microQuery custom Data
                    if (typeof el.elements[0].microQueryData.customdataset[key] !== "undefined")
                        delete el.elements[0].microQueryData.customdataset[key];
                });
        }
    }
    return this;
};


microQuery.prototype.attr = function(key, value)
{
    if (this.length > 0)
    {
        key = key.split("-").map((w, i) => (i > 0 ? w[0].toUpperCase() : w[0]) + w.slice(1)).join("");
        
        if (typeof value === "undefined")
            if (this.elements[0].attributes.getNamedItem(key) !== null)
                return this.elements[0].attributes.getNamedItem(key).value;
            else
                return null;
        else
        {
            if (value !== null)
                this.each(el => el.elements[0].setAttribute(key, value));
            else
                this.each(el => el.elements[0].removeAttribute(key.split(/(?=[A-Z])/).join("-").toLowerCase()));
        }
        
    }
    
    return this;
};


microQuery.prototype.find = function(selector)
{
    selector = selector.trim();
    
    // workaround to use ':selected' in selector (replace with ':checked')
    selector = selector.replace(":selected", ":checked");
    
    
    const returnElement = new microQuery();
    
    this.elements.forEach(element =>
    {
        if (["#text", "#comment"].indexOf(element.nodeName) === -1)
        {
            try
            {
                returnElement.elements = returnElement.elements.concat(Array.from(element.querySelectorAll(":scope " + selector)));
            }
            catch (e)
            {
                // workaround for IE/EDGE (not supporting :scope)
                const UUID = Date.now() + '';
                element.setAttribute('data-scope-uuid', UUID);
                returnElement.elements = returnElement.elements.concat(Array.from(element.querySelectorAll("[data-scope-uuid='" + UUID + "'] " + selector)));
                element.removeAttribute('data-scope-UUID');
            }
        }
    });
    
    return returnElement.init();
};


microQuery.prototype.each = function(callback)
{
    if (typeof callback === "function")
        this.elements.forEach((element, index) => callback(new microQuery(element).init(), index));
    return this;
};


microQuery.prototype.some = function(callback)
{
    if (typeof callback === "function")
        this.elements.some((element, index) => callback(new microQuery(element).init(), index));
    return this;
};


microQuery.prototype.first = function()
{
    const first = new microQuery();
    if (typeof this.elements[0] != "undefined")
        first.elements.push(this.elements[0]);
    return first.init();
};


microQuery.prototype.serialize = function()
{
    if (typeof this.elements[0] != "undefined" && this.elements[0].tagName === "FORM")
        return new FormData(this.elements[0]);
    
    return new FormData();
};


microQuery.prototype.parents = function(selector)
{
    const parents = new microQuery();
    let filterElement;
    if (selector !== undefined)
        filterElement = new microQuery(selector).init();
    
    this.elements.forEach(element =>
    {
        let parent = element;
        do
        {
            parent = parent.parentNode;
            if (selector === undefined || filterElement == null)
                parents.elements.push(parent);
            else
                filterElement.elements.forEach(filterElement => { if (filterElement === parent) parents.elements.push(parent); });
        }
        while (parent !== null && parent.nodeName !== "#document");
    });
    return parents.init();
};


microQuery.prototype.is = function(selector)
{
    let checkElement = new microQuery();
    switch (typeof selector)
    {
        case "object":
            // plain dom element
            if (typeof selector.nodeName !== "undefined")
                checkElement.elements.push(selector);
            // menten element
            else if (typeof selector.microQueryVersion !== "undefined")
                checkElement = selector;
            break
    }
    checkElement.init();
    
    // check
    return this.elements.some(el => checkElement.elements.some(checkEl => { if (el === checkEl) return true; }));
};


microQuery.prototype.index = function()
{
    if (this.length > 0)
        return Array.from(this.elements[0].parentNode.children).indexOf(this.elements[0]);
    else
        return null;
};


microQuery.prototype.css = function(key, value)
{
    if (value !== undefined)
    {
        this.elements.forEach(el => el.style[key] = value);
        return this;
    }
    else
        return this.elements[0].style[key] || null;
};


microQuery.prototype.table = function(options)
{
    // table obj
    const tableClass = function(t)
    {
        this.el = {};
        
        this.el.wrapper = null;
        this.el.scrollWrapper = null;
        
        this.el.table = t;
        
        this.el.filter = {wrapper: null, allSearch: null};
        
        this.el.head = {wrapper: null, original: null, cloned: null};
        this.el.body = {wrapper: null, original: null};
        this.el.foot = {wrapper: null, original: null, cloned: null};
        
        this.el.rowInfo = {wrapper: null};
        
        this.options = {
            default: {
                
                columnTypes: [],
                
                oddEven: true,
                
                rowCountInfo: true,
                rowCountInfoText1: "Zeige #1# Einträge",
                rowCountInfoText2: "Zeige #1# bis #2# von #3# Einträgen",
                rowCountInfoText3: "(gefiltert aus insgesamt #1# Einträgen)",
                
                emptyTableMessage: "Keine passenden Einträge gefunden",
                
                scrollY: "",
                scrollX: false,
                scrollCollapse: false,
                
                sort: true,
                initSortColumn: 1,
                initSortDirection: "asc",
                sortableColumns: [],
                allowSorting: true,
                sortArrows: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 85'><path id='sortArrowAsc' class='sortArrow' d='M30 20L40 40L20 40L0 40L10 20L20 0L30 20Z' fill='#000000'></path><path id='sortArrowDesc' class='sortArrow' d='M30 65L40 45L20 45L0 45L10 65L20 85L30 65Z' fill='#000000'></path></svg>",
                sortArrowColor: "#000",
                
                filter: {
                    allSearch: true,
                },
                
                pagination: false,
                
                dateLang: "en-US",
                dateOptions: {}
            },
            user: {
            
            }
        };
        
        // init rows functions
        // rows obj
        // #### start row functions ###
        const rows = function (tObj)
        {
            this.tObj = tObj;
        };
        
        rows.prototype.init = function ()
        {
            if (!this.emptyTableCheck())
            {
                // re-render odd/even row class
                this.renderOddEven();
            }
        };
        
        rows.prototype.createRow = function ()
        {
            // add new row
            const newRow = new microQuery("<tr></tr>").init();
            
            for (let i = 0; i < this.tObj.cols.length; i++)
                newRow.append("<td>" + (typeof arguments[i] !== "undefined" ? arguments[i] : "") + "</td>");
            
            // render right type data
            this.renderCells(newRow);
            
            this.tObj.el.body.original.append(newRow);
            
            // re-draw table
            this.tObj.update();
            
            return newRow;
        };
    
        rows.prototype.readRow = function (rowIndex)
        {
            return this.tObj.el.body.original.find("> tr:nth-child(" + (parseInt(rowIndex) + 1) + "):not(.d3Table_empty)");
        };
    
        rows.prototype.updateRow = function (row, updateObj)
        {
            row = new microQuery(row).init();
            if (row.length > 0)
            {
                for (let cellIndex in updateObj)
                {
                    let cell = row.find("> td:nth-child(" + (parseInt(cellIndex) + 1) + ")");
                    if (cell.length > 0)
                        cell.html(updateObj[parseInt(cellIndex)]).data("raw-value", null);
                }
                this.renderCells(row);
                
                // re-draw table
                this.tObj.update();
            }
            
            return row;
        };
    
        rows.prototype.deleteRow = function (row)
        {
            row = new microQuery(row).init();
            row.remove();
    
            // re-draw table
            this.tObj.update();
        };
    
        rows.prototype.get = function (onlyVisibile)
        {
            const trs = this.tObj.el.body.original.find("> tr:not(.d3Table_empty)");
            
            if (typeof onlyVisibile === "boolean" && onlyVisibile === true)
            {
                let filteredTrs = new microQuery();
                trs.each(tr =>
                {
                    if (
                        window.getComputedStyle(tr.elements[0]).visibility !== "collapse"
                        &&
                        window.getComputedStyle(tr.elements[0]).visibility !== "hidden"
                        &&
                        window.getComputedStyle(tr.elements[0]).display !== "none"
                    )
                        filteredTrs.elements.push(tr.elements[0]);
                });
                return filteredTrs.init();
            }
            
            return trs;
        };
    
        rows.prototype.renderCells = function (row)
        {
            const options = this.tObj.getMergedOptions();
            
            row.find("> td").each(cell =>
            {
                if (cell.data("raw-value") === null)
                {
                    let filterValue = cell.text();
                    switch (options.columnTypes[cell.index()])
                    {
                        case "int":
                            filterValue = filterValue !== "" ? parseInt(filterValue) : "";
                            break;
                        case "float":
                            filterValue = filterValue !== "" ? parseFloat(filterValue) : "";
                            break;
                        case "date":
                            cell.text(new Date(filterValue).toLocaleString(options.dateLang, options.dateOptions));
                            break;
                    }
                    cell.data("raw-value", filterValue);
                }
            });
        };
        
        rows.prototype.renderOddEven = function ()
        {
            if (!this.emptyTableCheck())
            {
                const options = this.tObj.getMergedOptions();
                if (options.oddEven) this.get(true).each((row, index) => row.removeClass("odd even").addClass((index + 1) % 2 === 0 ? "even" : "odd"));
            }
            return this;
        };
    
        rows.prototype.renderRowCountInfo = function ()
        {
            const options = this.tObj.getMergedOptions();
            if (options.rowCountInfo)
            {
                const allTrs = this.get();
                const allVisibleTrs = this.get(true);
                
                let rowCountInfoString = "";
                if (options.pagination)
                    rowCountInfoString = options.rowCountInfoText2.replace("#1#", allVisibleTrs.length > 0 ? "1" : "0").replace("#2#", allVisibleTrs.length).replace("#3#", allVisibleTrs.length);
                else
                    rowCountInfoString = options.rowCountInfoText1.replace("#1#", allVisibleTrs.length);
                    
                
                if (allTrs.length !== allVisibleTrs.length)
                    rowCountInfoString += " " + options.rowCountInfoText3.replace("#1#", allTrs.length);
                
                this.tObj.el.rowInfo.wrapper.html(rowCountInfoString);
            }
            return this;
        };
    
        rows.prototype.emptyTableCheck = function ()
        {
            const options = this.tObj.getMergedOptions();
            
            if (this.get(true).length === 0)
            {
                if (this.tObj.el.body.original.find(".d3Table_empty").length === 0)
                    this.tObj.el.body.original.append("<tr class='d3Table_empty'><td colspan='" + this.tObj.cols.length + "'>" + options.emptyTableMessage + "</td></tr>");
                return true;
            }
            else
            {
                this.tObj.el.body.original.find(".d3Table_empty").remove();
                return false;
            }
        };
        
        this.rows = new rows(this);
        // #### end row functions ###
    
        // init cols functions
        // cols obj
        // #### start cols functions ###
        const cols = function (tObj)
        {
            this.tObj = tObj;
            this.length = 0;
            
            this.types = options.columnTypes;
            
            this.sortDirection = options.initSortDirection;
            this.sortColumnNum = options.initSortColumn;
            
        };
        
        cols.prototype.init = function ()
        {
            // init column types
            this.tObj.rows.get().each(row => this.tObj.rows.renderCells(row));
    
            // count cols
            this.length = 0;
            this.tObj.el.head.original.find("tr:first-child > th,tr:first-child > td").each(col =>
            {
                const colspan = parseInt(col.attr("colspan"));
                this.length += !isNaN(colspan) ? colspan : 1;
            });
        };
        
        this.cols = new cols(this);
        // #### end cols functions ###
        
        return this;
    };
    
    
    tableClass.prototype.setUserOptions = function (options)
    {
        this.options.user = options;
        return this;
    };
    
    tableClass.prototype.getDefautOptions = function ()
    {
        return this.options.default;
    };
    
    tableClass.prototype.getUserOptions = function ()
    {
        return this.options.user;
    };
    
    tableClass.prototype.getMergedOptions = function ()
    {
        return Object.assign({},this.options.default, this.options.user);
    };
    
    
    
    tableClass.prototype.update = function ()
    {
        // re-filter rows
        this.filter();
        // re-sort rows
        this.sort(this.cols.sortColumnNum, this.cols.sortDirection);
        // re-render odd/even row higlighting
        this.rows.renderOddEven();
        // re-render row info
        this.rows.renderRowCountInfo();
    };
    
    
    
    tableClass.prototype.sort = function (colNum, direction)
    {
        const options = this.getMergedOptions();
        
        if (
            this.el.head.original != null
            &&
            options.sort
            &&
            Array.isArray(options.sortableColumns)
            &&
            !isNaN(colNum)
            &&
            (options.sortableColumns.length === 0 || options.sortableColumns.indexOf(colNum) !== -1)
        )
        {
            // get header cell
            let head = this.el.head.cloned;
            if (head === null) head = this.el.head.original;
            const headerCell = head.find("th:nth-child(" + colNum + "), td:nth-child(" + colNum + ")");
            
            let sortDirection = "asc";
            if (typeof direction == "string" && ["asc", "desc"].indexOf(direction.toLowerCase()) !== -1)
                sortDirection = direction;
            else if (headerCell.hasClass("asc"))
                sortDirection = "desc";
        
            // reset all sort arrows
            headerCell.parents("tr").first().find("th, td").removeClass("asc desc");
        
            // taggle the new sort arrow
            headerCell.addClass(sortDirection);
        
            const trs = this.rows.get();
            if (trs.length > 0)
            {
                // save values to row for sorting
                trs.each(tr => tr.data("temp-sort-value", tr.find("> td:nth-child(" + colNum + ")").data("raw-value")));
                // sort table
                trs.sort("data", "temp-sort-value", sortDirection);
                // remove sort temp data value
                trs.each(tr => tr.data("temp-sort-value", null));
            }
            // re-render odd/even rows
            this.rows.renderOddEven();
            
            // save sort infos
            this.cols.sortDirection = sortDirection;
            this.cols.sortColumnNum = colNum;
        }
        
        return this;
    };
    
    tableClass.prototype.filter = function ()
    {
        const options = this.getMergedOptions();
    
        // ### typed search ###
        if (this.el.filter.allSearch !== null)
        {
            const q = this.el.filter.allSearch.val().toLowerCase();
            this.rows.get().each(row =>
            {
                row.removeClass("searchAll_hidden");
                let searchHaystack = row.text();
                if (Array.isArray(options.filter.allSearch))
                {
                    searchHaystack = "";
                    options.filter.allSearch.forEach(index => searchHaystack += row.find(":nth-child(" + (index + 1) + ")").text() + " ");
                }
                if (searchHaystack.toLowerCase().indexOf(q) === -1)
                    row.addClass("searchAll_hidden");
            });
        }
        // ### typed search : END ###
        
    }
    
    
    tableClass.prototype.init = function ()
    {
        // check if table is already called on this table
        if (typeof this.el.table.elements[0].microQueryData !== "undefined" && typeof this.el.table.elements[0].microQueryData.tableObj !== "undefined" && this.el.table.elements[0].microQueryData.tableObj !== null)
        {
            console.warn("multiple init on same table.");
            return null;
        }
    
        // save tableObj in dom element
        this.el.table.elements[0].microQueryData.tableObj = this;
        
        const options = this.getMergedOptions();
        
        // build wrapper
        this.el.wrapper = new microQuery("<div class='d3Table_wrapper'></div>").init();
        this.el.table.insertBefore(this.el.wrapper);
        
        // move table into wrapper
        this.el.wrapper.append(this.el.table);
        
        // modify table
        this.el.table.addClass("d3Table").attr("table-type", "original");
        
        // get/save table sections
        this.el.head.original = this.el.table.find("> thead");
        if (this.el.head.original.length === 0) this.el.head.original = null;
        this.el.body.original = this.el.table.find("> tbody");
        if (this.el.body.original.length === 0) this.el.body.original = null;
        this.el.foot.original = this.el.table.find("> tfoot");
        if (this.el.foot.original.length === 0) this.el.foot.original = null;
        
        // init columns
        this.cols.init();
        
        // init rows
        this.rows.init();
        
        // init filter
        this.initFilter(options);
    
        // init row info
        this.initRowInfo(options);
        
        // init sorting
        this.initSorting(options);
        
        // init scrollable table
        this.initScrollableTable(options);
        
        return this;
    };
    
    
    tableClass.prototype.initFilter = function ()
    {
        const options = this.getMergedOptions();
        
        // check if some filter option is enabled
        if (
            (
                typeof options.filter.allSearch === "boolean"
                &&
                options.filter.allSearch === true
            )
            ||
            Array.isArray(options.filter.allSearch)
        )
        {
            // create filter wrapper
            this.el.filter.wrapper = new microQuery("<div class='d3Table_filter'></div>").init();
            
            // check if all search is enabled
            if (
                (
                    typeof options.filter.allSearch === "boolean"
                    &&
                    options.filter.allSearch === true
                )
                ||
                Array.isArray(options.filter.allSearch)
            )
            {
                this.el.filter.allSearch = new microQuery("<input class='allSearch' type='search' placeholder='Suche' />").init();
                
                // add key event
                let typeSearchTimeout = null;
                this.el.filter.allSearch.on("input", e =>
                {
                    if (typeSearchTimeout !== null) clearTimeout(typeSearchTimeout);
                    typeSearchTimeout = setTimeout(() => this.update(), 500);
                });
                
                this.el.filter.wrapper.append(this.el.filter.allSearch);
            }
            
            this.el.wrapper.prepend(this.el.filter.wrapper);
        }
    };
    
    tableClass.prototype.initRowInfo = function ()
    {
        const options = this.getMergedOptions();
        
        // check if some row info option is enabled
        if (options.rowCountInfo)
        {
            // create filter wrapper
            this.el.rowInfo.wrapper = new microQuery("<div class='d3Table_rowInfo'></div>").init();
            
            this.rows.renderRowCountInfo();
            
            this.el.wrapper.append(this.el.rowInfo.wrapper);
        }
    };
    
    tableClass.prototype.initSorting = function ()
    {
        const options = this.getMergedOptions();
        
        // check if sorting is enabled
        if (this.el.head.original != null && options.sort && (typeof options.initSortColumn === "number" && options.initSortColumn > 0))
        {
            const ths = this.el.head.original.find("> tr:last-child > th");
            if (ths.length > 0)
            {
    
                // add sort arrows and bind sort click event when manual sorting is enabled
                if (options.allowSorting)
                {
                    const _this = this;
                    
                    const thStyle = window.getComputedStyle(ths.elements[0]);
                    const thInnerHeight = parseFloat(thStyle.getPropertyValue("height")) - parseFloat(thStyle.getPropertyValue("padding-top")) - parseFloat(thStyle.getPropertyValue("padding-bottom"));
                    
                    const arrows = new microQuery(options.sortArrows).init();
                    arrows.css("position", "absolute");
                    arrows.css("height", "calc(0.8 * " + thInnerHeight + "px)");
                    arrows.css("right", "10px");
                    arrows.css("margin-top", "calc(0.1 * " + thInnerHeight + "px)");
                    arrows.find(".sortArrow").attr("fill", options.sortArrowColor);
                    
                    ths.each(th =>
                    {
                        if (Array.isArray(options.sortableColumns) && (options.sortableColumns.length === 0 || options.sortableColumns.indexOf(th.elements[0].cellIndex + 1) !== -1))
                        {
                            if (th.html() === "") th.html("&nbsp;");
                            
                            th.css("position", "relative");
                            th.css("cursor", "pointer");
                            th.append(arrows.clone());
                        }
                    });
                    
                    
                    this.el.head.original.on("click.sorting", "> tr > th", function ()
                    {
                        _this.sort(this.elements[0].cellIndex + 1);
                    });
                }
                
                // do init sort
                if (typeof options.initSortColumn === "number" && options.initSortColumn <= ths.length)
                    this.sort(options.initSortColumn, options.initSortDirection);
            }
        }
    };
    
    tableClass.prototype.initScrollableTable = function ()
    {
        const options = this.getMergedOptions();
        
        // check if vertical scroll should be used
        if (typeof options.scrollY === "string" && options.scrollY !== "")
        {
            // build wrappers
            this.el.scrollWrapper = new microQuery("<div class='d3Table_scroll'></div>").init();
            this.el.table.insertBefore(this.el.scrollWrapper);
            
            
            if (this.el.head.original != null)
            {
                this.el.head.wrapper = new microQuery("<div class='d3Table_head'><div class='d3Table_headInner'><table class='d3Table' table-type='helper'></table></div></div>").init();
                this.el.head.cloned = this.el.head.original.clone();
    
                // create wrapper div around cell content to hide content
                this.el.head.original.find("th,td").wrapInner("<div></div>");
                
                this.el.head.wrapper.find("table").append(this.el.head.cloned);
                
                // create resizce observer
                const headResizeObserver = new ResizeObserver(entries =>
                {
                    for (let entry of entries)
                        this.el.head.cloned.find("th:nth-child(" + (entry.target.cellIndex + 1) + ")").css("width", entry.target.clientWidth + "px");
    
                    // sets the header with
                    this.el.head.wrapper.find(".d3Table_headInner").css("width", this.el.body.wrapper.elements[0].clientWidth + "px");
                });
                
                // clone width
                this.el.head.original.find("th").each((th, index) =>
                {
                    this.el.head.cloned.find("th:nth-child(" + (index + 1) + ")").css("width", th.elements[0].clientWidth + "px");
    
                    // recalc on resize
                    headResizeObserver.observe(th.elements[0]);
                });
    
                this.el.scrollWrapper.append(this.el.head.wrapper);
            }
    
            if (this.el.body.original != null)
            {
                this.el.body.wrapper = new microQuery("<div class='d3Table_body'></div>").init();
                this.el.body.wrapper.append(this.el.table);
                this.el.scrollWrapper.append(this.el.body.wrapper);
            }
    
            if (this.el.foot.original != null)
            {
                this.el.foot.wrapper = new microQuery("<div class='d3Table_foot'><div class='d3Table_footInner'><table class='d3Table' table-type='helper'></table></div></div>").init();
                this.el.foot.cloned = this.el.foot.original.clone();
                
                // create wrapper div around cell content to hide content
                this.el.foot.original.find("th,td").wrapInner("<div></div>");
                
                this.el.foot.wrapper.find("table").append(this.el.foot.cloned);
        
                // create resizce observer
                const footResizeObserver = new ResizeObserver(entries =>
                {
                    for (let entry of entries)
                        this.el.foot.cloned.find("td:nth-child(" + (entry.target.cellIndex + 1) + ")").css("width", entry.target.clientWidth + "px");
            
                    // check for != width from original and cloned tfoot
                    if (this.el.foot.original.elements[0].clientWidth < this.el.foot.cloned.elements[0].clientWidth)
                        this.el.foot.wrapper.find(".d3Table_footInner").css("padding-right", (this.el.foot.cloned.elements[0].clientWidth - this.el.foot.original.elements[0].clientWidth) + "px");
                });
        
                // clone width
                this.el.foot.original.find("td").each((td, index) =>
                {
                    this.el.foot.cloned.find("td:nth-child(" + (index + 1) + ")").css("width", td.elements[0].clientWidth + "px");
            
                    // recalc on resize
                    footResizeObserver.observe(td.elements[0]);
                });
        
                this.el.scrollWrapper.append(this.el.foot.wrapper);
            }
            
            
            // make body scrollable
            this.el.body.wrapper.css("overflow-y", "scroll");
    
            let bodyHeight = options.scrollY;
            if (bodyHeight === "auto")
            {
                this.el.body.original.css("visibility", "collapse");
                
                const wrapperCS = window.getComputedStyle(this.el.wrapper.elements[0].parentNode);
                //console.log("wrapper", this.el.wrapper.elements[0].parentNode);
                //console.log("wrapperCS.height", wrapperCS.height);
                //console.log("wrapperCS.paddingTop", wrapperCS.paddingTop);
                //console.log("wrapperCS.paddingBottom", wrapperCS.paddingBottom);
                // 1. set height to parent height
                bodyHeight = parseFloat(wrapperCS.height + 0) - parseFloat(wrapperCS.paddingTop + 0) - parseFloat(wrapperCS.paddingBottom + 0);
                //console.log("init", bodyHeight);
                // 2. subtract the (offset from the table top to the inner parent top) from the parent height
                bodyHeight -= ( this.el.table.elements[0].getBoundingClientRect().top - this.el.wrapper.elements[0].parentNode.getBoundingClientRect().top - parseFloat(wrapperCS.paddingTop + 0) );
                //console.log("init2", bodyHeight);
                // 3. subtract table footer height
                if (this.el.foot.wrapper !== null)
                {
                    const footerWrapperCS = window.getComputedStyle(this.el.foot.wrapper.elements[0]);
                    bodyHeight -= (parseFloat(footerWrapperCS.height + 0) + parseFloat(footerWrapperCS.paddingTop + 0) + parseFloat(footerWrapperCS.paddingBottom + 0) + parseFloat(footerWrapperCS.marginTop + 0) + parseFloat(footerWrapperCS.marginBottom + 0));
                    //console.log("footer", bodyHeight);
                }
        
                // 4. subtract table row info height
                if (this.el.rowInfo.wrapper !== null)
                {
                    const rowInfoWrapperCS = window.getComputedStyle(this.el.rowInfo.wrapper.elements[0]);
                    bodyHeight -= (parseFloat(rowInfoWrapperCS.height + 0) + parseFloat(rowInfoWrapperCS.paddingTop + 0) + parseFloat(rowInfoWrapperCS.paddingBottom + 0) + parseFloat(rowInfoWrapperCS.marginTop + 0) + parseFloat(rowInfoWrapperCS.marginBottom + 0));
                    //console.log("rowInfo", bodyHeight);
                }
            
                    bodyHeight += "px";
                //console.log("result", bodyHeight);
                this.el.body.original.css("visibility", null);
            }
            
            this.el.body.wrapper.css(options.scrollCollapse ? "max-height" : "height", bodyHeight);
        }
        
        // check if horizontal scroll should be used
        if (typeof options.scrollX === "boolean" && options.scrollX)
        {
            // TODO:
        }
    };
    
    
    
    // ######################
    // #### init table/s ####
    // ######################
    const tableObjs = [];
    
    // hide all bodies when option scrollY is "auto"
    //if (options.scrollY === "auto") this.find("tbody").css("visibility", "collapse");
    
    this.each(table =>
    {
        // check if its a table
        if (table.elements[0].tagName === "TABLE")
        {
            // check if table has been inited
            if (typeof table.elements[0].microQueryData !== "undefined" && typeof table.elements[0].microQueryData.tableObj !== "undefined" && table.elements[0].microQueryData.tableObj !== null)
                tableObjs.push(table.elements[0].microQueryData.tableObj);
            else
            {
                const tableObj = new tableClass(table);
                tableObj.setUserOptions(options);
                if (tableObj.init() !== null)
                    tableObjs.push(tableObj);
            }
        }
    });
    
    // show again all bodies when option scrollY is "auto"
    //if (options.scrollY === "auto") this.find("tbody").css("visibility", null);
    
    if (tableObjs.length === 0)
        return null;
    else if(tableObjs.length === 1)
        return tableObjs[0];
    else
        return tableObjs;
};


/**
 * Upload function.
 * @param {Object} options
 * @param {string} options.url
 * @param {Object} [options.params]
 * @param {Function} [options.callback] gets triggert after each chunk is uploaded
 * @param {Function} [options.noFileCallback] gets triggert when no file is selected in selectors input
 *
 * @return {microQuery} this
 */
microQuery.prototype.upload = function (options)
{
    // check selected element
    if (this.length <= 0 || this.elements[0].tagName.toUpperCase() !== "INPUT" || this.elements[0].type.toUpperCase() !== "FILE")
    {
        console.warn("no valid input[type='file']");
        return this;
    }
    
    // check if upload is already running
    if (typeof this.elements[0].microQueryData.uploadStatus !== "undefined" && this.elements[0].microQueryData.uploadStatus !== null)
    {
        console.warn("Upload is running");
        return this;
    }
    
    // check if param is right type
    if (typeof options !== "object" || Array.isArray(options))
    {
        console.error("param is no object or is not set");
        return this;
    }
    
    // check for required options
    if (typeof options.url !== "string")
    {
        console.error("option.url is not set / no string");
        return this;
    }
    
    // check for custom files array in options
    const files = (Array.isArray(options.files) ? options.files : this.elements[0].files);
    
    // check if file/s are selected
    if (files.length === 0)
    {
        // call 'no file selected' callback if set in options
        if (typeof options.noFileCallback === "function") options.noFileCallback();
        return this;
    }
    
    // save upload status in dom element
    this.elements[0].microQueryData.uploadStatus = {
        el: this,
        options: options,
        status: "initialize",
        reader: new FileReader(),
        httpRequest: new XMLHttpRequest(),
        chunkSize: typeof options.chunkSize === "number" ? parseInt(options.chunkSize) : (1000 * 1024), // default 1MB
        fileCount: null,
        size: 0,
        sizeDone: 0,
        percentDone: 0,
        
        currentFile: {
            nextChunkStart: null,
            index: null,
            file: null,
            extention: "",
            sizeDone: 0,
            percentDone: 0
        },
        
        uploadFile: function () {},
        uploadChunk: function () {},
    };
    
    let uploadStatus = this.elements[0].microQueryData.uploadStatus;
    
    uploadStatus.fileCount = files.length;
    
    // calc files size sum
    for (let i = 0; i < uploadStatus.fileCount; i++)
        uploadStatus.size += files[i].size;
    
    uploadStatus.reader.onload = (event) =>
    {
        if (event.target.readyState !== FileReader.DONE) return;
        
        uploadStatus.httpRequest.onreadystatechange = (e) =>
        {
            if (e.target.readyState !== XMLHttpRequest.DONE) return;

            // calc current file sizeDone/percentDone
            uploadStatus.currentFile.sizeDone += event.loaded;
            uploadStatus.currentFile.percentDone = Math.floor((uploadStatus.currentFile.sizeDone / uploadStatus.currentFile.file.size) * 100);
            
            // calc all files sizeDone/percentDone
            uploadStatus.sizeDone += event.loaded;
            uploadStatus.percentDone = Math.floor((uploadStatus.sizeDone / uploadStatus.size) * 100);
            
            
            if (uploadStatus.currentFile.nextChunkStart < uploadStatus.currentFile.file.size)
                uploadStatus.uploadChunk(uploadStatus.currentFile.nextChunkStart);
            else
            {
                if (uploadStatus.currentFile.index + 1 < uploadStatus.fileCount)
                    uploadStatus.uploadFile(uploadStatus.currentFile.index + 1);
                else
                    uploadStatus.status = "done";
            }
            
            // call callback if set in options
            if (typeof uploadStatus.options.callback === "function") uploadStatus.options.callback(uploadStatus);
            
            // reset when upload done
            if (uploadStatus.status === "done") this.elements[0].microQueryData.uploadStatus = null;
        };
    
        uploadStatus.httpRequest.open("POST", uploadStatus.options.url);
        
        // set headers if in options param
        if (typeof uploadStatus.options.headers === "object" && !Array.isArray(uploadStatus.options.headers))
            for (let key in uploadStatus.options.headers)
                uploadStatus.httpRequest.setRequestHeader(key, uploadStatus.options.headers[key]);
        
        let params = new FormData();
        
        params.append("fileProperties", JSON.stringify({
            name: uploadStatus.currentFile.file.name,
            ext: uploadStatus.currentFile.extention,
            type: uploadStatus.currentFile.file.type,
            size: uploadStatus.currentFile.file.size,
            lastModified: uploadStatus.currentFile.file.lastModified,
        }));
        params.append("chunk", event.target.result);
        params.append("done", (uploadStatus.currentFile.nextChunkStart >= uploadStatus.currentFile.file.size));
    
        // check for given additional params
        if (typeof uploadStatus.options.params === "object" && !Array.isArray(uploadStatus.options.params))
            for (let key in uploadStatus.options.params)
                params.append(key, uploadStatus.options.params[key]);
        
        if (typeof params.get("filename") !== "undefined" && params.get("filename") !== null)
            uploadStatus.currentFile.newFileName = params.get("filename") + uploadStatus.currentFile.extention;
        else
            uploadStatus.currentFile.newFileName = uploadStatus.currentFile.file.name;
        
        uploadStatus.httpRequest.send(params);
    };
    
    
    uploadStatus.uploadChunk = (start) =>
    {
        uploadStatus.currentFile.nextChunkStart = start + uploadStatus.chunkSize + 1;
        let chunk = uploadStatus.currentFile.file.slice(start, uploadStatus.currentFile.nextChunkStart);
    
        uploadStatus.reader.readAsDataURL(chunk);
    };
    
    uploadStatus.uploadFile = (index) =>
    {
        if (index + 1 > files.length)
            return;
            
        uploadStatus.status = "running";
        uploadStatus.currentFile.file = files[index];
        uploadStatus.currentFile.nextChunkStart = 0;
        uploadStatus.currentFile.sizeDone = 0;
        uploadStatus.currentFile.percentDone = 0;
        uploadStatus.currentFile.extention = uploadStatus.currentFile.file.name.split(".").pop() !== uploadStatus.currentFile.file.name ? ("." + uploadStatus.currentFile.file.name.split(".").pop()) : "";
        uploadStatus.currentFile.index = index;
    
        // call callback before start if set in options
        if (typeof uploadStatus.options.callback === "function") uploadStatus.options.callback(uploadStatus);
    
        // start upload
        uploadStatus.uploadChunk(uploadStatus.currentFile.nextChunkStart);
    };
    
    
    // init/start upload first file
    uploadStatus.uploadFile(0);
    
    
    return this;
};


microQuery.prototype.pageOffset = function ()
{
    if (this.length > 0)
    {
        let pageOffset = {top: 0, left: 0};
        let elem = this.elements[0];
        if (elem.offsetParent)
        {
            do
            {
                pageOffset.top += elem.offsetTop;
                pageOffset.left += elem.offsetLeft;
                elem = elem.offsetParent;
            }
            while (elem);
        }
        return pageOffset;
    }
    return null;
};

/**
 * Generates a tooltip with content from the elements data-tooltip-content attribute.
 * @return {microQuery} this
 * @tutorial tooltip
 * @example
 * <a id="tooltip" data-tooltip-content="This is a cool tooltip">Hover me</a>
 *
 * <script>
 *  µ("#tooltip").tooltip();
 * </script>
 */
microQuery.prototype.tooltip = function ()
{
    // mark tooltip handle as handle
    this.addClass("micro-tooltip__handle");
    
    // add handle touch event to toggle the tooltip
    this.on("touch.micro-tooltip", function()
    {
        toggleTooltip(this);
    });
    
    // add handle mouseover event to toggle the tooltip
    this.on("mouseover.micro-tooltip", function()
    {
        toggleTooltip(this);
    });
    
    // add handle mouseout event to close the tooltip
    this.on("mouseout.micro-tooltip", function()
    {
        closeTooltips();
    });
    
    // add window scroll and click event to close all open tooltips
    new microQuery(window).init()
    .on("scroll.micro-tooltip", function()
    {
        closeTooltips();
    })
    .on("click.micro-tooltip", function(e)
    {
        const target = new microQuery(e.target).init();
        if (!target.hasClass("micro-tooltip__handle") && !target.hasClass("micro-tooltip") && target.parents(".micro-tooltip__handle").length === 0 && target.parents(".micro-tooltip").length === 0)
            closeTooltips();
    });
    
    // toggle tooltip function
    const toggleTooltip = function(handle)
    {
        const tooltipContent = handle.data("tooltip-content");
        if (tooltipContent !== null)
        {
            if (!handle.data("tooltip-open"))
            {
                const tooltip = new microQuery("<div class='micro-tooltip'>" + tooltipContent + "</div>").init();
            
                tooltip
                .css("position", "absolute")
                .css("background-color", "#fff")
                .css("border", "1px solid #000")
                .css("border-radius", "4px")
                .css("padding", "15px")
                .css("max-width", "400px")
                .css("z-index", "2");
            
                const tooltipArrow = new microQuery("<div class='micro-tooltip__arrow'></div>").init();
            
                tooltipArrow
                .css("position", "absolute")
                .css("height", "0")
                .css("width", "0")
                .css("border", "8px solid transparent")
                .css("border-top-color", "#000");
            
                tooltip.append(tooltipArrow);
            
                new microQuery("body").init().append(tooltip);
            
                const pageOffset = handle.pageOffset();
            
                tooltip
                .css("top", (pageOffset.top - tooltip.elements[0].offsetHeight - 12) + "px")
                .css("left", (pageOffset.left - (tooltip.elements[0].offsetWidth / 2) + (handle.elements[0].offsetWidth / 2) - parseFloat(getComputedStyle(tooltip.elements[0]).borderLeftWidth)) + "px")
            
                tooltipArrow
                .css("top", (tooltip.elements[0].offsetHeight - parseFloat(getComputedStyle(tooltip.elements[0]).borderBottomWidth)) + "px")
                .css("left", ((tooltip.elements[0].offsetWidth / 2) - (tooltipArrow.elements[0].offsetWidth / 2) - parseFloat(getComputedStyle(tooltip.elements[0]).borderLeftWidth)) + "px");
    
                handle.data("tooltip-open", true);
            }
            else
                closeTooltips();
        }
    }
    
    // close all open tooltips function
    const closeTooltips = function ()
    {
        new microQuery(".micro-tooltip").init().remove();
        new microQuery("[data-tooltip-open=true]").init().data("tooltip-open", null);
    }
    
    return this;
};


microQuery.prototype.ajax = function (url, settings)
{
    const supportedTypes = ["GET", "POST"];
    
    try
    {
        // check if request has settings
        if (typeof settings !== "object" || Array.isArray(settings))
            throw "settings param can not be empty";
        
        // check if request type is supported
        if (typeof settings.type === "undefined" || settings.type === null || supportedTypes.indexOf(settings.type.toUpperCase()) === -1)
            throw "unsupported request type (" + settings.type + ")";
    
        // validate header object
        if (typeof settings.headers !== "object" || Array.isArray(settings.headers))
            settings.headers = {};
    
        // add the required HTTP header for form data POST requests
        if (settings.type.toUpperCase() === "POST")
            settings.headers["Content-Type"] = "application/x-www-form-urlencoded";
        
        const request = new XMLHttpRequest();
        
        request.onreadystatechange = () =>
        {
            if (request.readyState === XMLHttpRequest.DONE && typeof settings.callback === "function")
                settings.callback(request);
        };
        
        // check params
        let data = {};
        
        // if data is FormData
        if (settings.data instanceof FormData)
            settings.data.forEach((value, key) => data[key] = value);
        else if (typeof settings.data === "object" && !Array.isArray(settings.data))
            data = settings.data;
    
        const urlSearchParamsString = new URLSearchParams(data).toString();
        
        // open request and set type and url
        request.open(settings.type, url + (settings.type.toUpperCase() === "GET" && urlSearchParamsString !== ""  ? "?" + urlSearchParamsString : ""), (typeof settings.async === "undefined" ? true : settings.async));
        
        // set headers
        if (typeof settings.headers === "object" && !Array.isArray(settings.headers))
            Object.keys(settings.headers).forEach(key => request.setRequestHeader(key,settings.headers[key]));
        
        request.send(urlSearchParamsString);
    }
    catch (message)
    {
        console.error("Error: '" + message + "'");
    }
};


microQuery.prototype.get = function (url, data, callback, settings)
{
    if (typeof settings !== "object" || Array.isArray(settings))
        settings = {};
    
    settings.type = "GET";
    settings.data = data;
    settings.callback = callback;
    
    this.ajax(url, settings);
};


microQuery.prototype.post = function (url, data, callback, settings)
{
    if (typeof settings !== "object" || Array.isArray(settings))
        settings = {};
    
    settings.type = "POST";
    settings.data = data;
    settings.callback = callback;
    
    this.ajax(url, settings);
};


const µ = function(selector)
{
    return new microQuery(selector).init();
};
